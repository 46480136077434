import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Config } from 'src/app/core/config/config';
import { url } from 'src/app/core/constants/urlConfig';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;

  constructor(private http: HttpClient, private msalService: MsalService, private router: Router) {}

  isSessionActive() {
    return localStorage.getItem(Config.userToken);
  }


  set token(token: string) {
    this.isLoggedIn = true;
    localStorage.setItem(Config.userToken, token);
  }

  get token(): string {
    const token = localStorage.getItem(Config.userToken)
    return token === null ? '' : token;
  }

  checkIsUserExist(idToken: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + idToken);
    return this.http.post(`${url}user-management/user-exists/`, null, {
      headers
    })
  }

  logout() {
    this.msalService.logout();
    localStorage.clear()
    this.router.navigate(['/auth']);
  }
  getUserDetails(): string{
    return localStorage.getItem("userDetails")!;
  }
}
