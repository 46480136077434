import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRoleService } from '../services/user-role.service';

@Injectable({
  providedIn: 'root'
})
export class IsAdminOneGuard implements CanActivate {
  userRole: string = "";
  constructor(private userRoleService: UserRoleService, private router: Router){
    const role = localStorage.getItem("role");
    this.userRole = role ? role : '';
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve) => {
      this.userRoleService.getUserRole()
      .then((response: any) => {
        if(response.data.role === 'Admin 1'){
          resolve(true);
        }
        else{
          resolve(false);
          this.router.navigate(['/']);
        }
      })
      .catch((error: any) => {
        resolve(false);
        this.router.navigate(['/']);
      })
    })
  }
}
