// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  backendUrl: 'https://cipla-timesheet-be-dev.devtomaster.com/',
  // backendUrl: 'https://f5c8-116-68-76-239.in.ngrok.io/',

  auth: {
    // authority: 'https://login.microsoftonline.com/a77c96d2-45eb-4c89-a6aa-f2be68f60123',
    //clientId: 'cd4df49d-8b29-46ee-a62a-5b0b9b1f6a02',
    authority:
      'https://login.microsoftonline.com/e6fd9f9f-6c49-4254-b8e8-90fba073cceb',
    clientId: '87847476-3f2c-4897-aa25-2de49c80fa75',
    redirectUri: 'https://cipla-timesheet-fe-dev.devtomaster.com',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
