import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { IsAdminOneGuard } from './core/guards/is-admin-one.guard';
import { IsAdminTwoGuard } from './core/guards/is-admin-two.guard';
import { isLoggedIn } from './core/guards/is-logged-in';
import { IsUserGuard } from './core/guards/is-user.guard';
import { PageNotFoundComponent } from './modules/shared/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [isLoggedIn],
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard, IsUserGuard],
  },
  {
    path: 'admin-one',
    loadChildren: () =>
      import('./modules/admin-one/admin-one.module').then(
        (m) => m.AdminOneModule
      ),
    canActivate: [AuthGuard, IsAdminOneGuard],
  },
  {
    path: 'admin-two',
    loadChildren: () =>
      import('./modules/admin-two/admin-two.module').then(
        (m) => m.AdminTwoModule
      ),
    canActivate: [AuthGuard, IsAdminTwoGuard],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
