import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { NotificationService } from '../services/notification.service';
import { id } from 'date-fns/locale';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService,
        private router: Router,
        private notificationService: NotificationService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {
        const headers: any = {
            Accept: 'application/json'
        };

        if (this.auth.isSessionActive()) {
            headers['Authorization'] = this.auth.token;
        }
        const request = req.clone({
            setHeaders: headers
        });
        return next.handle(request)
            .pipe(
                tap(resp => {
                    if (resp instanceof HttpResponse) {
                        if ((resp.body.code == 621) || (resp.body.code == 114)) {
                            this.notificationService.showNotification("Alert", resp.body.message)
                        }
                    }
                    return resp;
                }),
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) { // unauthorized request
                        localStorage.clear();
                        this.notificationService.showNotification("Alert", err.error.message)
                        this.router.navigate(["auth/login"]);
                    } else if (err.status === 403 || err.status === 412 || err.status === 400) {
                        if (err.status === 403) {
                            if(err.error.message ==="No such user"){
                                this.notificationService.showNotification("Alert","This is not a cipla account please logout and try other.")
                            }
                            else{
                                this.notificationService.showNotification("Alert", 'Session expired. Please login again');
                            }
                            localStorage.clear();
                            this.router.navigate(["auth/login"]);
                        }
                        else {
                            if(!err.error.error_type)
                            this.notificationService.showNotification("Alert", err.error.message)
                        }
                        if (err.error && (err.error.code == '401' || err.error.code == '403')) {
                            localStorage.clear();
                            this.router.navigate(["auth/login"]);
                        }
                    }
                    else if (err.status === 404 || err.status === 0) {
                        this.notificationService.showNotification("Alert", err.error.message);
                    }
                    return throwError(err);
                }),
            );
    }
}