import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {
  title: string = '';
  message: string = '';
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { title: string, message: string }, 
    private matSnackBar: MatSnackBar
    ) {}

  ngOnInit(): void {
    this.title =  this.data.title;
    this.message =  this.data.message;
  }
  closeSnackBar(){
    this.matSnackBar.dismiss();
  }
}
