import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/modules/shared/snack-bar/snack-bar.component';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private matSnackBar: MatSnackBar) {}
  showNotification(title: string, message: string) {
    this.matSnackBar.openFromComponent(SnackBarComponent, {
      duration: 3000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data: { title: title, message: message },
    });
  }
}
