import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { url } from '../constants/urlConfig';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  constructor(private httpClient: HttpClient) { }

  async getUserRole(){
    return lastValueFrom(this.httpClient.get<any>(`${url}user-management/get-user-role/`))
  }
}
