import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class isLoggedIn implements CanActivate {
  userRole: string = "";
  constructor(private router: Router,
    private auth: AuthService) {
    const role = localStorage.getItem("role");
    this.userRole = role ? role : '';
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.auth.token && this.userRole !== '') {
      switch (this.userRole) {
        case 'User': this.router.navigate(['/user']);
          break;
        case 'Admin 1': this.router.navigate(['/admin-one']);
          break;
        case 'Admin 2': this.router.navigate(['/admin-two']);
          break;
      }
      return false
    } else {
      return true
    }
  }

}
