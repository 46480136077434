import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';

import { filter, Subject, takeUntil } from 'rxjs';
import { Config } from './core/config/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // private readonly _destroying$ = new Subject<void>();

  constructor(private broadcastService: MsalBroadcastService) {}

  ngOnInit() {
    // this.broadcastService.msalSubject$
    //   .pipe(
    //     filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
    //     takeUntil(this._destroying$)
    //   )
    //   .subscribe((result: EventMessage) => {
    //     // Do something with event payload here
    //     console.log('asdf');

    //   });
    this.broadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),).subscribe((result: EventMessage) => {
      const payload = result.payload as AuthenticationResult;
      localStorage.setItem(Config.userToken, payload.idToken);
    });
  }

  // ngOnDestroy(): void {
  //   this._destroying$.next(undefined);
  //   this._destroying$.complete();
  // }
}
